
export const POPUP_KEY = 'TRBO_popup';

function exist() {
  return typeof window !== 'undefined' && window.sessionStorage;
}

function set(status) {
  if (!exist) {
    return;
  }
  window.sessionStorage.setItem(POPUP_KEY, status);
}

function get() {
  return (exist() && window.sessionStorage.getItem(POPUP_KEY)) || 'show';
}

export const popupHelper = {
  set,
  get,
  exist,
};
