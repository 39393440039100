import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './MapToggle.module.css';

const MapToggle = props => {
  const { isMapVariantShow, handleMapVariant } = props;

  return (
    <div className={css.toggleWrapper}>
      <span className={classNames(css.toggleItem, { [css.toggleItemActive]: !isMapVariantShow })} onClick={() => handleMapVariant(false)}>
        <FormattedMessage id="SearchPage.hideMap" />
      </span>
      <div
        className={classNames(css.toggle, { [css.toggleActive]: isMapVariantShow })}
        onClick={() => handleMapVariant(!isMapVariantShow)}
      >
      </div>
      <span className={classNames(css.toggleItem, { [css.toggleItemActive]: isMapVariantShow })} onClick={() => handleMapVariant(true)}>
        <span className={css.desktopText}>
          <FormattedMessage id="SearchPage.showMap" />
        </span>
        <span className={css.mobileText}>
          <FormattedMessage id="SearchPage.map" />
        </span>
      </span>
    </div>
  );
};

export default MapToggle;
