import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
// import { uniqBy } from 'lodash';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import {
  H3,
  H5,
  Page,
  LandingPopup,
  Modal,
  LayoutSingleColumn
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import { camelize } from '../../util/string';
import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import MapToggle from './MapToggle/MapToggle';

import { popupHelper as popup } from './utils';

import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng, LatLngBounds } = sdkTypes;

import css from './SearchPage.module.css';
import { fetchCurrentUser, setFavoritesListingIdToUserProfile } from "../../ducks/user.duck";
import { ASSET_NAME } from './SearchPage.duck';
import isEqual from "lodash/isEqual";

// const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.selectorRef = React.createRef(null);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      isMapVariantShow: false,
      currentPage: 1,
      landingPopupIsOpen: popup.get() === "show",
      listingsWasUpdated: false
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    this.handleMapVariant = this.handleMapVariant.bind(this);
    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  onUpdateSearchRange = (value) => {
    console.log(value, 'onUpdateSearchRange')
    this.setState({
      listingsWasUpdated: value
    })
  }

  onWindowScroll(e) {
    const { history, routeConfiguration, location, pagination } = this.props;
    // const top = +e.target.scrollingElement.scrollTop === 0;
    const bottom = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop === e.target.scrollingElement.clientHeight;

    // if(this.state.currentPage === 1) return
    // if(top) {
    //   this.setState({currentPage: this.state.currentPage - 1})
    //   history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {page: this.state.currentPage}))
    // }

    const selectedFilters = parse(location.search);

    const searchParams = {
      address: selectedFilters?.address || null,
      bounds: selectedFilters?.bounds || null,
      origin: selectedFilters?.origin || null,

      dates: selectedFilters?.dates || null,
      pub_additionalServicesPresent: selectedFilters?.pub_additionalServicesPresent || 'basicPrice',
      pub_itemType: selectedFilters?.pub_itemType || null,
      page: selectedFilters.page + 1 || this.state.currentPage + 1
    };

    if (this.state.currentPage >= pagination?.totalPages || selectedFilters.page >= pagination?.totalPages) return

    if (bottom) {
      // this.setState({currentPage: this.state.currentPage + 1})
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams))
    }
  }

  componentDidMount() {
    console.log('mount')
    // const { config, } = this.props;
    // const { listingFields: listingFieldsConfig } = config?.listing || {};
    // const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    // const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    // const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams, pub_additionalServicesPresent: 'basicPrice' };
    // const search = cleanSearchFromConflictingParams(
    //   searchParams,
    //   listingFieldsConfig,
    //   defaultFiltersConfig,
    //   sortConfig
    // );

    // if (!parse(location.search)?.pub_additionalServicesPresent) {
    //   history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    // }

    this.setState({
      listingsWasUpdated: false
    })

    let storedFavorites;
    if (typeof window !== 'undefined' && window.localStorage) {
      this.setState({ isMapVariantShow: window.localStorage.getItem('TRBO_ShowMap') === "showMap" ? true : false });
      storedFavorites = JSON.parse(window.localStorage.getItem('favorites'))
    }

    window && window.addEventListener('scroll', this.onWindowScroll);

    const { location, onUpdateProfile, isAuthenticated } = this.props;


    if (isAuthenticated && storedFavorites?.fromFavorites) {
      const data = storedFavorites?.data;
      onUpdateProfile(data).then(() => {
        if (typeof window !== 'undefined' && window.localStorage) {
          window.localStorage.removeItem('favorites');
        }

      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }


  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    // return

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig, dropNonFilterParams),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    // const queryParams = omit(urlQueryParams, filterQueryParamNames);
    const queryParams = {
      pub_additionalServicesPresent: "basicPrice"
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    // const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);


      const { location } = this.props;
      const urlQueryParams = validUrlQueryParamsFromProps(this.props);
      const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams, pub_additionalServicesPresent: 'basicPrice' };
      const search = cleanSearchFromConflictingParams(
        searchParams,
        listingFieldsConfig,
        defaultFiltersConfig,
        sortConfig
      );

      if (!parse(location.search)?.pub_additionalServicesPresent) {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
      }
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  handleMapVariant(state) {
    this.setState({ isMapVariantShow: state })

    if (typeof window !== 'undefined' && window.localStorage) {

      if (!this.state.isMapVariantShow) {
        window.localStorage.setItem('TRBO_ShowMap', 'showMap');
      } else {
        window.localStorage.setItem('TRBO_ShowMap', 'hideMap');
      }
    }
  }

  onClosePopup() {
    this.setState({ landingPopupIsOpen: false })
    popup.set('hide');
  }

  onUpdateSearchRange = (value) => {
    console.log(value, 'onUpdateSearchRange')
    this.setState({
      listingsWasUpdated: value
    })
  }

  render() {
    const {
      intl,
      // allListings,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      history,
      config,
      currentUser,
      pageAssetsData,
      inProgress,
      error
    } = this.props;

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    // const isWindowDefined = typeof window !== 'undefined';
    // const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    // const shouldShowSearchMap =
    //   !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    // const availablePrimaryFilters = [...customPrimaryFilters, ...defaultFilters];
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    // const keysOfSelectedFilters = Object.keys(selectedFilters);
    // const selectedFiltersCountForMobile = isKeywordSearch
    //   ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
    //   : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, customSecondaryFilters, [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
        isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
        },
        selectedSecondaryFiltersCount,
      }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
          ? listings.length
          : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const typeFilter = availableFilters.filter(item => item.key === "itemType");
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
        urlQueryParams={urlQueryParams}
        typeFilter={typeFilter}
      />
    );

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    // const typeFilter = availableFilters.filter(item => item.key === "itemType");
    const additionalServicesFilter = availableFilters.filter(item => item.key === "additionalServicesPresent");
    const allCategoriesFilter = availableFilters.filter(item => item.key === "itemType");
    const showFullPrice = validQueryParams?.pub_additionalServicesPresent === 'fullRates';

    const { isMapVariantShow } = this.state;

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutSingleColumn
          className={css.pageRoot}
          topbar={
            <TopbarContainer
              className={topbarClasses}
              currentPage="SearchPage"
              currentSearchParams={urlQueryParams}
              onUpdateSearchRange={this.onUpdateSearchRange}
            />
          }
          footer={<FooterContainer />}
        >
          <div className={css.container} ref={this.selectorRef}>
            <div className={css.topFiltersContainer}>
              <SearchFiltersPrimary className={css.categoriesFilter} {...propsForSecondaryFiltersToggle}>
                {allCategoriesFilter.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersPrimary.${config.key}`}
                      idPrefix="SearchFiltersPrimary"
                      config={config}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      showAsPopup
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      additionalServices={parse(location?.search)?.pub_additionalServicesPresent}
                      customMenuLabelClass={css.categoriesButton}
                      customMenuClass={css.categoriesMenu}
                    />
                  );
                })}
              </SearchFiltersPrimary>

              {typeFilter.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersPrimary.${config.key}`}
                    idPrefix="SearchFiltersPrimary"
                    config={config}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    liveEdit
                    showAsPopup={false}
                    withIcons
                  />
                );
              })}
            </div>



            {!isMapVariantShow && (
              <div className={css.searchResultContainer}>

                {/* <SearchFiltersMobile
                className={css.searchFiltersMobileMap}
                urlQueryParams={validQueryParams}
                sortByComponent={sortBy('mobile')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                noResultsInfo={noResultsInfo}
                isMapVariant
              >
                {additionalServicesFilter.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.key}-${parse(location.search)?.pub_additionalServicesPresent}`}
                      idPrefix="SearchFiltersMobile"
                      config={config}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersMobile> */}
                <MainPanelHeader
                  className={css.mainPanelMapVariant}
                  sortByComponent={sortBy('desktop')}
                  isSortByActive={sortConfig.active}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  noResultsInfo={noResultsInfo}
                  handleMapVariant={this.handleMapVariant}
                  isMapVariantShow={isMapVariantShow}
                  listingsWasUpdated={this.state.listingsWasUpdated}
                >
                  <>
                    <MapToggle isMapVariantShow={isMapVariantShow} handleMapVariant={this.handleMapVariant} />
                    <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                      {additionalServicesFilter.map(config => {
                        return (
                          <FilterComponent
                            key={`SearchFiltersPrimary.${config.key}`}
                            idPrefix="SearchFiltersPrimary"
                            config={config}
                            marketplaceCurrency={marketplaceCurrency}
                            urlQueryParams={validQueryParams}
                            initialValues={initialValues(this.props, this.state.currentQueryParams)}
                            getHandleChangedValueFn={this.getHandleChangedValueFn}
                            intl={intl}
                            showAsPopup
                            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                            additionalServices={parse(location?.search)?.pub_additionalServicesPresent}
                          />
                        );
                      })}
                    </SearchFiltersPrimary>
                  </>
                </MainPanelHeader>
                {isSecondaryFiltersOpen ? (
                  <div className={classNames(css.searchFiltersPanel)}>
                    <SearchFiltersSecondary
                      urlQueryParams={validQueryParams}
                      listingsAreLoaded={listingsAreLoaded}
                      applyFilters={this.applyFilters}
                      cancelFilters={this.cancelFilters}
                      resetAll={this.resetAll}
                      onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                    >
                      {customSecondaryFilters.map(config => {
                        return (
                          <FilterComponent
                            key={`SearchFiltersSecondary.${config.key}`}
                            idPrefix="SearchFiltersSecondary"
                            config={config}
                            marketplaceCurrency={marketplaceCurrency}
                            urlQueryParams={validQueryParams}
                            initialValues={initialValues(this.props, this.state.currentQueryParams)}
                            getHandleChangedValueFn={this.getHandleChangedValueFn}
                            intl={intl}
                            showAsPopup={false}
                          />
                        );
                      })}
                    </SearchFiltersSecondary>
                  </div>
                ) : (
                  <div
                    className={classNames(css.listingsForMapVariant, {
                      [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                    })}
                  >
                    {searchListingsError ? (
                      <H3 className={css.error}>
                        <FormattedMessage id="SearchPage.searchError" />
                      </H3>
                    ) : null}
                    {!isValidDatesFilter ? (
                      <H5>
                        <FormattedMessage id="SearchPage.invalidDatesFilter" />
                      </H5>
                    ) : null}

                    <SearchResultsPanel
                      className={css.searchListingsPanel}
                      listings={listings}
                      pagination={listingsAreLoaded ? pagination : null}
                      search={parse(location.search)}
                      setActiveListing={onActivateListing}
                      isMapVariant
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                      mapCenter={origin || null}
                      history={history}
                      routeConfiguration={routeConfiguration}
                      showFullPrice={showFullPrice}
                      searchInProgress={searchInProgress}
                    />

                  </div>
                )}
              </div>
            )}

            <div className={classNames(css.mapWrapper, { [css.mapWrapperShown]: isMapVariantShow })} data-testid="searchMapContainer">
              <div className={classNames(css.mapFilters, css.mapFiltersTop)}>
                <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                  {additionalServicesFilter.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.key}`}
                        idPrefix="SearchFiltersPrimary"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        additionalServices={parse(location?.search)?.pub_additionalServicesPresent}
                      />
                    );
                  })}
                </SearchFiltersPrimary>
              </div>
              <div className={classNames(css.mapFilters, css.mapFiltersBottom)}>
                <MapToggle isMapVariantShow={isMapVariantShow} handleMapVariant={this.handleMapVariant} />
              </div>

              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                bounds={bounds || new LatLngBounds(new LatLng(73.25321265, 163.35851875), new LatLng(-44.52495399, -161.48523125))}
                // bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                location={location}
                listings={listings || []}
                onMapMoveEnd={this.onMapMoveEnd}
                onCloseAsModal={() => {
                  onManageDisableScrolling('SearchPage.map', false);
                }}
                messages={intl.messages}
                onUpdateSearchRange={this.onUpdateSearchRange}
              />
            </div>

            {/* <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
          </ModalInMobile> */}

            <Modal
              usePortal
              id="LandingPage.popup"
              isOpen={this.state.landingPopupIsOpen}
              onClose={() => this.onClosePopup()}
              onManageDisableScrolling={onManageDisableScrolling}
              closeButtonMessage={" "}
              widePopup
              transparentPopup
            >
              <LandingPopup
                onClosePopup={() => this.onClosePopup()}
                inProgress={inProgress}
                error={error}
                data={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
              />
            </Modal>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;

  const {
    currentPageResultIds,
    currentPageAllResults,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;

  const allListingsIds = currentPageAllResults.map(l => l?.id)
  const allListings = getListingsById(state, allListingsIds);

  const listings = getListingsById(state, currentPageResultIds);
  const { currentUser } = state.user;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  return {
    listings,
    allListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
    isAuthenticated,
    pageAssetsData,
    inProgress,
    error
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onUpdateProfile: data => dispatch(setFavoritesListingIdToUserProfile(data)),
  onFetchCurrentUser: data => dispatch(fetchCurrentUser(data)),

});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
